import * as React from "react";

const ArrowIcon = ({ fill = "#fff" }) => (
  <svg
    width={21}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.187.458a.725.725 0 0 0-.714.725V13.942L4.91 9.38a.725.725 0 0 0-1.025 1.026l5.8 5.8.008.007.001.001a.74.74 0 0 0 .022.021h.001l.03.027h.001c.005.005.012.01.02.015l.001.001.034.024.003.002.026.016.004.002.03.016.003.002a.23.23 0 0 0 .026.012l.005.002.029.013.004.002.033.012h.005a.691.691 0 0 0 .025.01l.005.001c.007.002.02.006.033.008h.004a.31.31 0 0 0 .03.007h.004a.503.503 0 0 0 .031.005h.004a.342.342 0 0 0 .035.004h.001l.038.001h.036a.646.646 0 0 0 .064-.004h-.003.009-.004a.736.736 0 0 0 .049-.009l-.004.001a.973.973 0 0 0 .039-.008l-.005.001a.488.488 0 0 0 .032-.008l-.005.001.048-.014h-.005l.031-.01-.004.001a.438.438 0 0 0 .042-.018l-.004.002.06-.03-.005.002.005-.003-.003.002a.54.54 0 0 0 .044-.027l-.002.002c.011-.009.021-.015.03-.021h-.002a.843.843 0 0 0 .048-.037l.004-.004.033-.03 5.8-5.799a.725.725 0 0 0-.505-1.245h-.023a.724.724 0 0 0-.498.219l-4.562 4.562V1.184c0-.4-.324-.724-.725-.724h-.011l.001-.002ZM.774 19.316h-.01a.725.725 0 1 0 0 1.45H19.63a.725.725 0 1 0 0-1.45H.775Z"
      fill={fill}
    />
  </svg>
);

export default ArrowIcon;
