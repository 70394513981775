import React, { useRef } from "react";
import ReactDOM from "react-dom";

import "./styles.css";
import { Controller, Scene } from "react-scrollmagic";
import Sequence from "./Sequence";
import Partners from "./components/Partners";
import { useWindowDimension } from "./hooks";

const App = () => {
  const ref = useRef();
  const [width, height] = useWindowDimension();
  console.log(width);

  return (
    <>

    <div className="App" key={`app-${width}`}>
      <Controller>
        <Scene duration={10000} triggerHook="onLeave" pin>
          {(progress) => (
            <div
              style={{
                height: "100vh",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <img
                src="/assets/texture.png"
                className="sequence-container__image"
              />
              <div className="sequence-container">
                {/* <div className="nav-bar">
                  <div className="nav-bar__logo">
                    <Logo />
                  </div>
                  <button className="nav-bar__button">Get in touch!</button>
                </div> */}
                <Sequence ref={ref} progress={progress} />
              </div>
            </div>
          )}
        </Scene>
      </Controller>
      <div>
        <div className="section-container">
          <Partners />
        </div>
        <div className="footer">
          <div className="footer-container">
            <span>© 2021 Urbs. All rights reserved.</span>
            <span>info@urbs.systems</span>
            <img src="/assets/urbs-light.png" alt="urbs light logo" />
          </div>
        </div>
      </div>
    </div>
    <div className="app-portrait">
      <img src="/assets/tilt-phone-right.gif" alt="app orientation" />
    </div>
    </>
  );
};

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
