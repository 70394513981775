import React, { forwardRef, useEffect, useState } from "react";

function pad(n) {
  var s = "000" + n;
  return s.substr(s.length - 4);
}

function getCurrentFrame(index) {
  return `https://ik.imagekit.io/UrbsNYC/urbs/urbs-${index}.png`;
}

const ImageSequence = forwardRef(({ progress, numFrames = 309 }, ref) => {
  const [newImages, setImages] = useState([]);

  function preloadImages() {
    for (let i = 1; i <= numFrames; i++) {
      const img = new Image();
      const imgSrc = getCurrentFrame(i);
      img.src = imgSrc;
      setImages((prevImages) => [...prevImages, img]);
    }
  }

  let index = Math.round(progress * 1 * (newImages.length - 1));

  useEffect(() => {
    preloadImages();
  }, []);

  return newImages.map((item, i) => (
    <div
      style={{
        display: i !== index ? "none" : "block",
      }}
      className={index < 150 ? "base-container blue-base-container" : "base-container green-base-container"}
    >
        <span
          ref={ref}
          key={i}
          className="sequence-image"
          style={{
            display: i !== index ? "none" : "block",
            height: "100%",
            width: "100%",
            backgroundImage: `url('https://ik.imagekit.io/UrbsNYC/urbs/urbs-${index}.png')`,
            // backgroundSize: "contain",
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            // backgroundAttachment: "fixed",
          }}
        />
        <div className={index < 150 ? "blue-base" : "green-base"}></div>
        {!i && (
          <div className="sequence-copy">
            <span>Bringing the color back – 2030</span> <span>New York</span>
          </div>
        )}
    </div>
  ));
});

export default ImageSequence;
