import React, { useRef } from "react";
import ArrowIcon from "./ArrowIcon";

const partnersData = [
  {
    name: "Hudson Square Properties",
    logo: "/assets/hudson.png",
  },
  {
    name: "Hines",
    logo: "/assets/hines.png",
  },
  {
    name: "Urbs",
    logo: "/assets/urbs-dark.png",
  },
  {
    name: "Buro Happold",
    logo: "/assets/happold.png",
  },
  {
    name: "Modio",
    logo: "/assets/modio.png",
  },
  {
    name: "Blur Print Power",
    logo: "/assets/blueprint-logo-color.png",
  },
  {
    name: "Noda",
    logo: "/assets/noda.png",
  },
  {
    name: "Energy Machines",
    logo: "/assets/energy-machines.png",
  },
  {
    name: "IFC",
    logo: "/assets/ifc.png",
  },
  {
    name: "Bloc Power",
    logo: "/assets/bloc-power.png",
  },
  {
    name: "Willis Towers Watson",
    logo: "/assets/wtw.png",
  },
];

export default function Partners() {
  return (
    <div>
      <div className="partners__header--container">
        <h2 className="partners__header">Pioneering the Future.</h2>
        <a className="partners-report__button" href="/assets/Pioneering The Future.pdf" download="Pioneering The Future.pdf">
          <ArrowIcon />
          <span>Download our report</span>
        </a>
      </div>
      <div className="partners_details__content">
        {partnersData.slice(0, 6).map((item) => (
          <div className="partners_details__content--item">
            <img key={item.logo} src={item.logo} alt={item.name} />
          </div>
        ))}
      </div>
      <div className="partners_details__content stage">
        {partnersData.slice(6, 11).map((item) => (
          <div className="partners_details__content--item">
            <img key={item.logo} src={item.logo} alt={item.name} />
          </div>
        ))}
      </div>
    </div>
  );
}
